import type { Id, UpdateOptions, ToastOptions, IconType as ToastIconType, Content } from 'vue3-toastify';
import { UiIcon } from '#components';
type ToastOptionsAdditional = ToastOptions & {
  icon?: boolean | IconName | ToastIconType;
};
type OmitTypeOptions = Omit<ToastOptionsAdditional, 'type'>;
const useToast = (defaultMessage?: Content, defaultOptions?: ToastOptionsAdditional) => {
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n;
  const open = (message?: Content, options?: ToastOptionsAdditional) => {
    const currentMessage = message || defaultMessage || '';
    const currentOptions = Object.assign({}, defaultOptions, options);

    if (isString(currentOptions.icon) || currentOptions.icon === undefined) {
      currentOptions.icon = h(UiIcon, {
        name: currentOptions.icon || 'error_outline',
        type: 'material'
      });
    }
    if (nuxtApp.$toast) {
      return nuxtApp.$toast(isString(currentMessage) ? t(currentMessage) : currentMessage, currentOptions);
    }
  };

  const info = (message?: Content, options?: OmitTypeOptions) =>
    open(message, {
      ...(options || {}),
      type: 'info',
      icon: 'error_outline'
    });

  const warn = (message?: Content, options?: OmitTypeOptions) =>
    open(message, {
      ...(options || {}),
      type: 'warning',
      icon: 'warning_amber'
    });

  const error = (message?: Content, options?: OmitTypeOptions) =>
    open(message, {
      ...(options || {}),
      type: 'error',
      icon: 'error_outline'
    });
  const success = (message?: Content, options?: OmitTypeOptions) =>
    open(message, {
      ...(options || {}),
      type: 'success',
      icon: 'check_circle_outline'
    });
  const remove = (toastId?: Id) => nuxtApp.$toast.remove(toastId);
  const clearAll = (containerId?: Id, withExitAnimation?: boolean) =>
    nuxtApp.$toast.clearAll(containerId, withExitAnimation);
  const done = (id: Id) => nuxtApp.$toast.done(id);
  const update = (toastId: Id, options?: UpdateOptions) => nuxtApp.$toast.update(toastId, options);

  return {
    open,
    info,
    success,
    warn,
    error,
    done,
    update,
    remove,
    clearAll
  };
};
export default useToast;
