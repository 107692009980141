<template>
  <component
    :is="tag"
    :class="classes"
  >
    <ui-image
      v-if="src"
      :src="src"
      :alt="userName"
      class="w-full"
      :class="imgClass"
      disabled-lazy-load
    />
    <span
      v-else-if="userInitial"
      class="text-white block"
    >
      {{ userInitial }}
    </span>
    <ui-icon
      v-else
      name="person"
    />
    <slot />
  </component>
</template>
<script setup lang="ts">
  import { type AvatarCssProps, avatarClass } from '~/components/ui/Avatar/styles';

  type Props = {
    tag?: keyof HTMLElementTagNameMap;
    src?: string;
    userName?: string;
    imgClass?: ClassesType;
  } & AvatarCssProps;

  const props = withDefaults(defineProps<Props>(), {
    tag: 'span',
    size: 'xl',
    userName: 'avatar',
    rounded: 'full'
  });

  const attrs = useAttrs();

  const classes = computed(() => {
    return cn(
      avatarClass({
        size: props.size,
        border: props.border,
        rounded: props.rounded
      }),
      { 'bg-primary': !props.src },
      attrs.class as string
    );
  });

  const userInitial = computed(() => {
    const nameWithoutEmoji = removeEmoji(props.userName);

    return nameWithoutEmoji
      .split(/[ -]/)
      .map(part => part.charAt(0).toUpperCase())
      .join('')
      .replace(/[^A-Z]/g, '')
      .substring(0, 2);
  });
</script>
