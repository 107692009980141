<template>
  <div class="min-h-48 p-5 flex items-center justify-center text-center flex-1">
    <ui-typography
      variant="h2"
      :text="message"
    />
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'default'
  });
  const message = MessagesError.meetAuthError;
</script>
