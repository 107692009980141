<template>
  <div
    v-if="roomStore.room"
    class="flex gap-2.5"
  >
    <ui-avatar
      :src="roomStore.room.avatar_url"
      :user-name="roomStore.room.name"
      class="rounded-md shrink-0"
      img-class="h-full object-cover"
      size="sm"
    />
    <div :class="titleClass">
      <ui-typography
        class="text-primary mb-0.5"
        :class="{ truncate: small }"
      >
        {{ roomStore.room.name }}
      </ui-typography>
      <common-room-counters
        :room="roomStore.room"
        variant="small"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  type Props = { titleClass?: ClassesType; small?: boolean };
  withDefaults(defineProps<Props>(), {
    titleClass: 'pr-2'
  });
  const roomStore = useRoomStore();
</script>
