type Options = {
  onPause?: () => undefined;
  immediate?: boolean;
};

const useDownTimer = (initialVal: MaybeRef<number> = 160, options: Options = {}) => {
  const { onPause, immediate } = {
    onPause: () => {},
    immediate: false,
    ...options
  };
  const contdDown = ref<number>(unref(initialVal) || 0);
  const formatTime = useSecondToMMSS(contdDown);
  const { pause, resume, isActive } = useIntervalFn(
    () => {
      if (contdDown.value <= 0) {
        reset();
        onPause();
        return;
      }
      contdDown.value--;
    },
    1000,
    {
      immediate
    }
  );
  const reset = (time = unref(initialVal)) => {
    pause();
    contdDown.value = time;
  };
  const start = (time = unref(initialVal)) => {
    contdDown.value = time;
    resume();
  };

  return {
    isActive,
    contdDown,
    formatTime,
    pause,
    start,
    reset
  };
};

export default useDownTimer;
