const useProp = <R = any, P extends object = object, K extends keyof P = any>(
  props: MaybeRef<P>,
  key?: K,
  defaultValue?: any
) => {
  return computed<R>(() => {
    const val = unref(props);
    if (key) {
      return useGet(props, key, defaultValue);
    }
    return val || defaultValue;
  });
};
export default useProp;
