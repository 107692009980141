import { cva, type VariantProps } from 'class-variance-authority';

const btnSizeIcons = {
  'xs-icon': 'w-6 h-6 text-base',
  'sm-icon': 'w-8 h-8 text-base',
  'md-icon': 'h-8 w-8 md:h-10 md:w-10 text-xl',
  'lg-icon': 'w-8 h-8 md:w-12 md:h-12 text-xl',
  'xl-icon': 'w-12 h-12 md:h-16 md:w-16 text-2xl'
};

const disabledLink = ' aria-disabled:pointer-events-none aria-disabled:!text-action-disabled';

const disabled =
  ' aria-disabled:pointer-events-none aria-disabled:!bg-action-disabled aria-disabled:!text-white  aria-disabled:!border-action-disabled';
const disabledGroup =
  ' group-aria-disabled:pointer-events-none group-aria-disabled:!bg-action-disabled group-aria-disabled:!text-white  group-aria-disabled:!border-action-disabled';
const baseAction =
  ' [&.active]:bg-action-secondary-hover [&.active]:border-action-secondary-hover [&.active]:text-action-secondary-hover-text hover:bg-action-secondary-hover hover:border-action-secondary-hover hover:text-action-secondary-hover-text focus:bg-action-secondary-focus focus:border-action-secondary-focus focus:text-action-secondary-focus-text' +
  disabled;

const extendVariants = cva('', {
  variants: {
    variant: {
      primary: '',
      'primary-light': '',
      secondary:
        'text-action group-hover:text-action-text group-focus:text-action-text group-[&.active]:text-action-text' +
        disabledLink,
      'secondary-outline': '',
      ghost: '',
      gray: '',
      link:
        'bg-action text-action-text-outside group-hover:bg-action-hover group-hover:text-action-hover-text-outside group-focus:bg-action-focus group-focus:text-action-focus-text-outside group-[.active]:bg-action-hover group-[.active]:text-action-hover-text-outside' +
        disabledGroup,
      'secondary-link': '',
      error: '',
      'secondary-error': '',
      'error-link':
        'bg-action-error text-action-error-text group-hover:bg-action-error-hover group-hover:text-action-error-hover-text group-focus:bg-action-error-focus group-focus:text-action-error-focus-text' +
        disabledGroup,
      custom: ''
    }
  }
});

const btnVariants = cva(
  'base-button group cursor-pointer border inline-flex items-center outline-none text-center justify-center relative font-medium group',
  {
    variants: {
      variant: {
        primary:
          'bg-action border-action text-action-text [&.active]:bg-action-hover [&.active]:border-action-hover [&.active]:text-action-hover-text hover:bg-action-hover hover:border-action-hover hover:text-action-hover-text focus:bg-action-focus  focus:border-action-focus focus:text-action-focus-text' +
          disabled,
        'primary-light': 'bg-action-secondary border-action-secondary text-action' + baseAction,
        secondary: 'bg-action-secondary border-action-secondary text-action-secondary-text' + baseAction,
        'secondary-outline': 'border-action bg-action-secondary text-action-secondary-text' + baseAction,
        ghost: 'border-transparent bg-transparent text-action-secondary-text' + baseAction,
        gray: 'border-action-gray bg-action-gray text-action-gray-text' + baseAction,
        link:
          'border-0 text-action hover:text-action-hover focus:text-action-focus [&.active]:text-action-hover' +
          disabledLink,
        'secondary-link':
          'border-0 text-action-secondary-text hover:text-action-secondary-hover focus:text-action-secondary-focus' +
          disabledLink,
        error:
          'bg-action-error border-action-error text-action-error-text [&.active]:bg-action-error-hover [&.active]:border-action-error-hover [&.active]:text-action-error-text-hover hover:bg-action-error-hover hover:border-action-error-hover hover:text-action-error focus:bg-action-error-focus focus:border-action-error-focus focus:text-action-error',
        'secondary-error':
          'bg-action-error-secondary border-action-error-secondary text-action-error-secondary-text hover:bg-action-error-secondary-hover hover:border-action-error-secondary-hover hover:text-action-error-secondary-text-hover focus:bg-action-error-secondary-focus focus:border-action-error-secondary-focus focus:text-action-error-secondary-text-focus' +
          disabled,
        'error-link': 'border-0 text-action-error hover:text-action-error/90 focus:text-action-error/60' + disabledLink,
        custom: ''
      },
      size: {
        xs: 'min-h-9 min-w-16 py-1 px-1.5 text-xs',
        sm: 'min-h-6 min-w-16 py-1 px-1.5 text-xs',
        md: 'md:min-w-32 py-1.5 px-3 md:min-h-10 md:py-1.5 md:px-4 text-sm',
        lg: 'min-h-9 min-w-32 p-2.5  md:min-h-[3.125rem] text-sm',
        xl: 'min-h-[3.125rem] min-w-32 md:py-4 md:px-5 p-3 md:min-h-16 text-base md:text-2xl',
        none: '',
        ...btnSizeIcons
      },
      rounded: {
        sm: 'rounded-md',
        md: 'rounded',
        lg: 'rounded-lg',
        full: 'rounded-full',
        none: 'rounded-none'
      },
      underline: {
        hover: 'no-underline hover:underline focus:underline',
        none: 'no-underline',
        always: 'underline',
        base: 'underline hover:underline-none focus:underline-none'
      }
    },
    defaultVariants: {
      variant: 'primary',
      underline: 'none',
      rounded: 'md'
    }
  }
);

const btnVariantsContent = cva('flex flex-row w-full items-center', {
  variants: {
    loading: {
      true: 'invisible'
    },
    direct: {
      center: 'justify-center',
      left: 'justify-start',
      right: 'justify-end'
    },
    size: {
      xs: 'gap-0.5',
      sm: 'gap-0.5',
      md: 'gap-1',
      lg: 'gap-2',
      xl: 'gap-2.5'
    }
  },
  defaultVariants: {
    direct: 'center',
    size: 'xl'
  }
});

type Props = VariantProps<typeof btnVariants>;
type PropsContent = VariantProps<typeof btnVariantsContent>;
type PropsIconExtend = VariantProps<typeof extendVariants>;

type ButtonCssProps = {
  variant?: Props['variant'];
  iconVariant?: PropsIconExtend['variant'];
  size?: Props['size'];
  rounded?: Props['rounded'];
  underline?: Props['underline'];
  direct?: PropsContent['direct'];
};

export { type ButtonCssProps, btnVariants, btnVariantsContent, btnSizeIcons, extendVariants };
