import revive_payload_client_Sub88kQ7pw from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0zUPmrhy04 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VesRqFpG6p from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jEHZBByDJg from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@3.29.5_vite@5.4.10_@types+node@22.8.1_sass@1.80_o2fb55zzxxwuniac2cksuhzvty/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_dhGE0oFTjo from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2sizjr01xw from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qjTB5WeLHc from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Ceg7NPI2cx from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3hjslkWEoO from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@3.29.5_typescript@5.5.4_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dating-frontend/dating-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_Q9GfwaOvms from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.1_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__jcawm2o2iidtwyix5t3ydceida/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_4CrbAYYoWW from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@3.29.5_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_R3BbJeS3Nz from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_jqD0tIk8ea from "/home/runner/work/dating-frontend/dating-frontend/modules/nuxt-auth-sanctum/runtime/plugin.ts";
import http_plugin_nuxt_6oYvuAibbt from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.5_nuxi@3.15.0_rollup@3.29.5_sass@1.80.4_typescript@5.5.4_v_ql4aerlyajvdgxho7nxeludahy/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import switch_locale_path_ssr_S8yvyOXUBA from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_7ULPcRj5Re from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_72g9Hj8Ik2 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.2_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@3.29.5_typescript@5_63m3pntaq4ks2c2ass7q6isrbm/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_client_2Jozffge65 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@3.29.5_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_ztwumCCasp from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@3.29.5_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import nuxt_plugin_rJuoJXd6KR from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@3.29.5_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_olOT1wh5TV from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.10_@types+node@22.8.1_sass@1.80.4__xwqrmpygbfmnognvzzduwt6slu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_xZnvMqgdE8 from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.10_@types+node@22.8.1_sass@1.80.4__xwqrmpygbfmnognvzzduwt6slu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import titles_kVXiRKL4VP from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.3_h3@1.13.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.1_6gvawhk6b2ud6m5jkmdszuj2sq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_y4hLm8Njzl from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.3_h3@1.13.0_magicast@0.3.5_rollup@3.29.5_vite@5.4.1_6gvawhk6b2ud6m5jkmdszuj2sq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_MwUV1rKkjy from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@3.29.5_vite@5.4.10_@types+node@22.8.1_s_neicuowb2anjzb7hqg5xd7vj4y/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_Fqg8HayhJX from "/home/runner/work/dating-frontend/dating-frontend/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@3.29.5_vite@5.4.10_@types+node@22.8.1_s_neicuowb2anjzb7hqg5xd7vj4y/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import api_routes_Q0W49V3wqt from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/api-routes.ts";
import custom_pwa_register_client_fOvh2D2KfW from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/custom-pwa-register.client.ts";
import dompurify_R8xb7bJPqP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/dompurify.ts";
import floating_1Jsyq3dLZq from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/floating.ts";
import handle_app_color_mn7Bwa8Xck from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/handle-app-color.ts";
import modal_saNsw8xGBA from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/modal.ts";
import pusher_js_client_2TZYA6fBVP from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/pusher-js.client.ts";
import select_fkZYP3esEZ from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/select.ts";
import toast_notification_ycBNrvS44W from "/home/runner/work/dating-frontend/dating-frontend/src/plugins/toast-notification.ts";
export default [
  revive_payload_client_Sub88kQ7pw,
  unhead_0zUPmrhy04,
  router_VesRqFpG6p,
  _0_siteConfig_jEHZBByDJg,
  payload_client_dhGE0oFTjo,
  navigation_repaint_client_2sizjr01xw,
  check_outdated_build_client_qjTB5WeLHc,
  chunk_reload_client_Ceg7NPI2cx,
  plugin_vue3_3hjslkWEoO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Q9GfwaOvms,
  plugin_wy0B721ODc,
  plugin_client_4CrbAYYoWW,
  plugin_client_R3BbJeS3Nz,
  plugin_jqD0tIk8ea,
  http_plugin_nuxt_6oYvuAibbt,
  switch_locale_path_ssr_S8yvyOXUBA,
  i18n_7ULPcRj5Re,
  plugin_72g9Hj8Ik2,
  plugin_client_2Jozffge65,
  plugin_ztwumCCasp,
  nuxt_plugin_rJuoJXd6KR,
  pwa_icons_olOT1wh5TV,
  pwa_client_xZnvMqgdE8,
  titles_kVXiRKL4VP,
  defaults_y4hLm8Njzl,
  siteConfig_MwUV1rKkjy,
  inferSeoMetaPlugin_Fqg8HayhJX,
  api_routes_Q0W49V3wqt,
  custom_pwa_register_client_fOvh2D2KfW,
  dompurify_R8xb7bJPqP,
  floating_1Jsyq3dLZq,
  handle_app_color_mn7Bwa8Xck,
  modal_saNsw8xGBA,
  pusher_js_client_2TZYA6fBVP,
  select_fkZYP3esEZ,
  toast_notification_ycBNrvS44W
]