import { FetchError } from 'ofetch';
import { defineNuxtPlugin, useRoute } from '#app';
import { createHttpClient } from './httpFactory';
import { useSanctumAuth } from './composables/useSanctumAuth';
import { useSanctumConfig } from './composables/useSanctumConfig';

export default defineNuxtPlugin(async nuxtApp => {
  const { user, parseUserData } = useSanctumAuth();
  const client = createHttpClient();
  const options = useSanctumConfig();
  const route = useRoute();

  const shouldFetchUser =
    user.value === null &&
    nuxtApp.ssrContext &&
    !options.disabledFetchRoutePath?.some(path => route.path.includes(path));

  if (shouldFetchUser) {
    try {
      const userData = await client(options.endpoints.user);
      user.value = parseUserData(userData);
    } catch (error) {
      if (error instanceof FetchError && [401, 419].includes((error as any)?.response?.status)) {
        // Unauthenticated user, unable to get information
      } else {
        console.error('Unable to load user identity', error);
      }
    }
  }

  return {
    provide: {
      sanctumClient: client
    }
  };
});
