import { cva, type VariantProps } from 'class-variance-authority';

const avatarClass = cva('overflow-hidden text-white group flex justify-center items-center relative', {
  variants: {
    size: {
      none: '',
      xs: 'h-8 w-8 text-xs',
      sm: 'lg:h-12 lg:w-12 h-8 w-8 text-sm',
      md: 'h-14 w-14 md:h-20 md:w-20 lg:h-24 lg:w-24 text-3xl',
      lg: 'h-20 w-20 lg:h-32 lg:w-32 text-4xl',
      xl: 'h-24 w-24 lg:h-36 lg:w-36 text-5xl'
    },
    border: {
      true: 'border border-primary'
    },
    rounded: {
      full: 'rounded-full',
      sm: 'rounded-sm',
      md: 'rounded'
    }
  }
});

type AvatarClassVariants = VariantProps<typeof avatarClass>;

type AvatarCssProps = {
  size?: AvatarClassVariants['size'];
  border?: AvatarClassVariants['border'];
  rounded?: AvatarClassVariants['rounded'];
};

export { type AvatarCssProps, avatarClass };
