import type { ToastOptions } from 'vue3-toastify';
import { CommonJitsiToastUserLobby, CommonJitsiToastBaseNotify, CommonJitsiToastAskedUnmute } from '#components';

const useLoadJitsiScript = () => {
  const loading = ref(true);
  const meetStore = useMeetStore();
  const error = ref();
  const { onLoaded, onError } = useScript({
    src: '/libs/jitsi/lib-jitsi-meet.min.js',
    async: true,
    defer: true,
    // @ts-expect-error
    body: true
  });

  onLoaded(() => {
    loading.value = false;
    meetStore.onInit();
  });
  onError(err => {
    error.value = err;
  });

  return {
    error,
    loading
  };
};

const getJitsiOptions = () => {
  const { JITSI_DOMAIN } = useRuntimeConfig().public;
  const roomStore = useRoomStore();

  return computed(() => {
    const roomName = roomStore.roomId + '-meet';

    return {
      roomName,
      hosts: {
        domain: JITSI_DOMAIN,
        muc: 'conference.' + JITSI_DOMAIN
      },
      //  openBridgeChannel: true,
      serviceUrl: `wss://${JITSI_DOMAIN}/xmpp-websocket?room=${roomName}`,
      // websocketKeepAliveUrl: `https://${JITSI_DOMAIN}/_unlock?room=${roomName}`,
      enableNoAudioDetection: true,
      enableNoMicDetection: true,
      defaultLocalDisplayName: 'me',
      flags: {
        sourceNameSignaling: true,
        sendMultipleVideoStreams: true,
        receiveMultipleVideoStreams: true
      },
      // Video quality / constraints
      constraints: {
        video: {
          height: {
            ideal: 720,
            max: 1080,
            min: 360
          },
          width: {
            ideal: 1280,
            max: 1920,
            min: 640
          }
        }
      },
      resolution: 1080,
      maxBitratesVideo: {
        low: 200000,
        standard: 500000,
        high: 1500000
      },
      videoQuality: {
        preferredCodec: 'VP9',
        maxBitratesVideo: {
          VP8: {
            low: 200000,
            standard: 500000,
            high: 1500000
          },
          VP9: {
            low: 300000,
            standard: 700000,
            high: 2000000
          },
          H264: {
            low: 200000,
            standard: 500000,
            high: 1500000
          }
        }
      },
      //  channelLastN: 25,

      // Enable Peer-to-Peer for 1-1 calls
      p2p: {
        enabled: false
      },

      apiLogLevels: 'error',
      // Logging
      logging: {
        // Default log level
        defaultLogLevel: 'error',

        // The following are too verbose in their logging with the default level
        'modules/RTC/TraceablePeerConnection.js': 'error',
        'modules/statistics/CallStats.js': 'error',
        'modules/xmpp/strophe.util.js': 'error'
      }

      // End marker, disregard
      // __end: true,
      // enableLayerSuspension: true,
      // useNewBandwidthAllocationStrategy: true
    };
  });
};

type NotificationsAudio = {
  id: string;
  src: string;
};

type NotificationDataItem = {
  key: string;
  message?: string;
  component?: Component;
  audio?: NotificationsAudio | null;
  toastOptions?: ToastOptions;
};

const notificationsData: Record<JitsiNotificationKey, NotificationDataItem> = {
  addUserToLobby: {
    key: 'addUserToLobby-',
    component: CommonJitsiToastUserLobby,
    audio: {
      id: 'addUserToLobby',
      src: '/libs/jitsi/sounds/knock.mp3'
    }
  },
  joinedUser: {
    key: 'joinedUser-',
    message: 'JITSI.NOTIFY.JOINED_USER',
    audio: {
      id: 'joinedUser',
      src: '/libs/jitsi/sounds/joined.mp3'
    },
    toastOptions: {
      autoClose: 1000
    }
  },
  leftUser: {
    key: 'leftUser-',
    message: 'JITSI.NOTIFY.LEFT_USER',
    audio: {
      id: 'leftUser',
      src: '/libs/jitsi/sounds/left.mp3'
    },
    toastOptions: {
      autoClose: 1000
    }
  },
  muteRemote: {
    key: 'muteRemote-',
    message: 'JITSI.NOTIFY.MUTE_REMOTE',
    toastOptions: {
      autoClose: 1000
    }
  },
  askToUnmute: {
    key: 'askToUnmute-',
    component: CommonJitsiToastAskedUnmute,
    audio: {
      id: 'askToUnmute',
      src: '/libs/jitsi/sounds/asked-unmute.mp3'
    }
  },
  handUp: {
    key: 'handUp-',
    message: 'JITSI.NOTIFY.HAND_UP',
    audio: {
      id: 'handUp',
      src: '/libs/jitsi/sounds/reactions-raised-hand.mp3'
    },
    toastOptions: {
      autoClose: 1000
    }
  }
};

const jitsiNotificationsAudio = useTransform(
  notificationsData,
  (acc, curr) => {
    if (curr.audio) {
      acc.push(curr.audio);
    }
    return acc;
  },
  [] as NotificationsAudio[]
);

const useJitsiNotification = () => {
  const toast = useToast();

  const playAudio = async (key: JitsiNotificationKey) => {
    const audioEl = (
      notificationsData[key].audio?.id ? document.getElementById(notificationsData[key].audio?.id) : null
    ) as HTMLAudioElement | null;

    if (!audioEl || getPlayerStatus(audioEl) === VideoShareStatus.Play) {
      return;
    }
    audioEl.currentTime = 0;
    await audioEl.play();
  };

  const addNotification = (
    key: JitsiNotificationKey,
    userId: string,
    tProps: { user?: UserMeet; tData?: Record<string, any> } = {}
  ) => {
    const data = notificationsData[key];
    if (!data || (!data.message && !data.component)) {
      return;
    }
    const toastId = data.key + userId;

    const props: any = {
      userId,
      onClose() {
        toast.remove(toastId);
      },
      ...tProps
    };

    if (!data.component && data.message) {
      props.message = data.message;
    }

    toast.open(h(data.component || CommonJitsiToastBaseNotify, props), {
      toastId,
      icon: false,
      closeButton: false,
      ...(data?.toastOptions || {})
    });
  };

  const removeNotify = (key: JitsiNotificationKey, userId: string) => toast.remove(notificationsData[key].key + userId);

  return {
    addNotification,
    removeNotify,
    playAudio
  };
};

export { useLoadJitsiScript, getJitsiOptions, useJitsiNotification, jitsiNotificationsAudio };
