<template>
  <ui-fields-wrap
    ref="wrapRef"
    :label="label"
    :filed-wrap-class="filedWrapClass"
    :label-two="labelTwo"
    :icon-right="iconRight"
    :icon-left="iconLeft"
    :on-click-right-icon="onClickRightIcon"
    :on-click-left-icon="onClickLeftIcon"
    :required="required"
    :input-id="inputId"
    :hint="hint"
    :has-value="hasValue"
    :disabled="disabled"
    :error="errorMessage"
    :autofocus="autofocus"
    :placeholder="placeholder"
    floating-top
  >
    <template #default="{ inputId, setRef, inputClasses, onFocus, onBlur }">
      <textarea
        :id="inputId"
        :ref="el => setRef(onSetRef(el as HTMLTextAreaElement))"
        :value="value"
        :name="name"
        :disabled="disabled"
        :style="computedStyles"
        :placeholder="placeholder || ''"
        :class="inputClasses"
        v-bind="$attrs"
        :rows="rows"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @change="onChange"
      />
    </template>
    <template
      v-for="(_, slotName) in $slots"
      #[slotName]="slotData"
    >
      <slot
        :name="slotName"
        v-bind="slotData"
      />
    </template>
  </ui-fields-wrap>
</template>

<script setup lang="ts">
  defineOptions({
    inheritAttrs: false
  });

  const props = withDefaults(defineProps<TextareaFieldProps>(), {
    name: props => props.name || '',
    rows: 3,
    minHeight: 48,
    maxHeight: 200,
    autosize: true
  });

  const emit = defineEmits<TextFiledEmit>();
  const nuxtApp = useNuxtApp();
  const textareaRef = ref<HTMLTextAreaElement>();
  const { value, errorMessage, hasValue, onChange, onInput } = useTextField(props, emit);

  const maxHeightScroll = ref(false);
  const height = ref<string | number | undefined>('auto');

  const computedStyles = computed(() => {
    if (!props.autosize) {
      return {};
    }
    return {
      resize: 'none',
      height: height.value,
      overflow: maxHeightScroll.value ? 'auto' : 'hidden'
    };
  });

  const resize = async () => {
    if (!props.autosize || !textareaRef.value) {
      return;
    }
    height.value = 'auto';
    await nextTick();
    let contentHeight: number = textareaRef.value.scrollHeight + 1;
    if (props.minHeight) {
      contentHeight = +(contentHeight < +props.minHeight ? props.minHeight : contentHeight);
    }
    if (props.maxHeight) {
      if (contentHeight > +props.maxHeight) {
        contentHeight = +props.maxHeight;
        maxHeightScroll.value = true;
      } else {
        maxHeightScroll.value = false;
      }
    }
    height.value = contentHeight + 'px';
  };

  const delayResize = () => nextTick(resize);

  const onSetRef = (el: HTMLTextAreaElement) => {
    textareaRef.value = el;
    return el;
  };

  watch(() => [props.minHeight, props.maxHeight, value.value], delayResize);
  watch(() => props.autosize, resize);

  onMounted(delayResize);

  onBeforeUnmount(nuxtApp.hook('page:transition:finish', delayResize));
</script>
