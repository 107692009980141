import type { MaybeRef } from 'vue';

type OptionsValidate = {
  onSelect: (files: File | File[]) => void;
  onRemove: (data: { file: File; files: File[] }) => void;
};

const useValidateFile = (props: MaybeRef<PropsValidateFile> = {}, options: Partial<OptionsValidate> = {}) => {
  const dropWrap = ref<HTMLElement>();
  const { handler, loading } = useHandlerErrorAndLoadingClient();
  const files = ref<File[]>([]);
  const fileInput = ref<HTMLInputElement>();
  const { t } = useI18n();
  const { error } = useToast();
  const computedProps = computed(() => {
    const unrefProps = unref(props);
    return {
      ...unrefProps,
      maxFileSize: unrefProps.maxFileSize || MaxFileUploadSize,
      accept: unrefProps.accept || allTypes.accepts,
      fileLimit: unrefProps.fileLimit || MaxFileLimit
    };
  });
  const hasFiles = computed(() => !!files.value.length);
  const prepareAccept = computed(() => computedProps.value.accept?.join?.(','));

  const totalFilesCount = computed(() => {
    return files.value.length + (computedProps.value.attachmentFilesCount || 0);
  });

  const isFileLimit = computed(() => totalFilesCount.value >= computedProps.value.fileLimit);
  const chooseDisabled = computed(() => {
    return computedProps.value.disabled || isFileLimit.value;
  });

  const fileUpload = () => {
    if (computedProps.value.upload && hasFiles.value) {
      handler(async () => await computedProps.value.upload?.(files.value));
    }
  };

  const getTypeClass = (fileType: string) => {
    return fileType.substring(0, fileType.indexOf('/'));
  };

  const isFileSelected = (file: File) => {
    if (computedProps.value.replaceFile && files.value?.length) {
      for (const sFile of files.value) {
        if (sFile.name + sFile.type + sFile.size === file.name + file.type + file.size) return true;
      }
    }

    return false;
  };

  const isFileTypeValid = (file: File) => {
    const acceptableTypes = computedProps.value.accept || [];

    for (const type of acceptableTypes) {
      const acceptable = type.includes('*')
        ? getTypeClass(file.type) === getTypeClass(type)
        : file.type === type || useToLower('.' + getFileExtension(file.name)) === useToLower(type);

      if (acceptable) {
        return true;
      }
    }

    return false;
  };

  const validate = (file: File) => {
    if (computedProps.value.accept && !isFileTypeValid(file)) {
      error(
        t(MessagesError.invalidFileType, {
          name: file.name,
          types: getExtByTypes(computedProps.value.accept).join(', ')
        })
      );
      return false;
    }

    if (computedProps.value.maxFileSize && file.size > computedProps.value.maxFileSize) {
      error(
        t(MessagesError.invalidFileSize, {
          name: file.name,
          size: formatFileSize(computedProps.value.maxFileSize)
        })
      );
      return false;
    }

    return true;
  };

  const clearInputElement = () => {
    if (fileInput.value) {
      fileInput.value.value = '';
    }
  };

  const onSelect = () => {
    options.onSelect?.(computedProps.value.multiple ? files.value : files.value[0]);

    if (computedProps.value.resetOnSelect) {
      clearInputElement();
      files.value = [];
    }
  };

  const handleFileValidation = (file: File): boolean => {
    return !isFileSelected(file) && validate(file);
  };

  const onFileSelect = (event: Event | File[]) => {
    if (chooseDisabled.value) {
      clearInputElement();
      return;
    }
    files.value = computedProps.value.multiple ? files.value || [] : [];
    const eventFiles = isArray(event) ? event : (event as FileInputEvent).target?.files;

    if (eventFiles) {
      const newFilesCount = totalFilesCount.value + eventFiles.length;
      let availableSlots = eventFiles.length;
      if (newFilesCount > computedProps.value.fileLimit) {
        error(
          t(MessagesError.invalidFileLimit, {
            max: computedProps.value.fileLimit
          })
        );
        availableSlots = computedProps.value.fileLimit - totalFilesCount.value;
      }

      let addedFilesCount = 0;
      for (const file of eventFiles) {
        if (addedFilesCount >= availableSlots) {
          break;
        }
        if (handleFileValidation(file)) {
          files.value.push(file);
          addedFilesCount++;
        }
      }
    }

    onSelect();
    fileUpload();
    clearInputElement();
  };

  const onRemoveFile = (index: number) => {
    clearInputElement();
    const removedFile = files.value.splice(index, 1)[0];

    files.value = [...files.value];
    onSelect();
    options.onRemove?.({
      file: removedFile,
      files: files.value
    });
  };

  const setDropWrap = (el?: any) => {
    if (!computedProps.value.disabledDrop) {
      dropWrap.value = el;
    }
  };

  const onDrop = (dropFiles: File[] | null) => {
    if (computedProps.value.disabledDrop || chooseDisabled.value || !dropFiles) {
      return;
    }
    const allowDrop = computedProps.value.multiple || (dropFiles && dropFiles.length === 1);
    if (allowDrop) {
      onFileSelect(dropFiles as File[]);
    }
  };

  const { isOverDropZone } = useDropZone(dropWrap, onDrop);
  const isDrop = computed(() => !computedProps.value.disabledDrop && !chooseDisabled.value && isOverDropZone.value);

  return {
    fileInput,
    dropWrap,
    files,
    loading,
    prepareAccept,
    chooseDisabled,
    isDrop,

    onRemoveFile,
    onFileSelect,
    setDropWrap,
    onDrop
  };
};

export default useValidateFile;
