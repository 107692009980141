const USER_CHANNEL_KEY = 'users';
const ROOM_CHANNEL_KEY = 'presence-rooms';

const EVENTS = {
  NotificationCreate: 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
  RoomMessageCreated: 'RoomMessageCreated',
  RoomMessageUpdate: 'RoomMessageUpdated',
  RoomMessageDelete: 'RoomMessageDeleted'
};

const subscribeUserNotificationChannel = () => {
  if (IS_SERVER) {
    return;
  }
  const { user } = useAuthUser();
  const { isAuthenticated } = useSanctumAuth();
  const notificationStore = useNotificationStore();
  const { channel, createChannel, destroyChannel } = usePusherChannel(
    computed(() => (user.value?.id ? USER_CHANNEL_KEY + '.' + user.value?.id : null)),
    USER_CHANNEL_KEY,
    true
  );

  usePusherEvent(channel, EVENTS.NotificationCreate, notification => {
    notificationStore.addNotification(notification);
  });

  watch(
    isAuthenticated,
    val => {
      if (val) {
        createChannel();
        return;
      }
      destroyChannel();
    },
    {
      immediate: true
    }
  );
};

const subscribeRoomNotificationChannel = () => {
  if (IS_SERVER) {
    return;
  }
  const roomStore = useRoomStore();
  const chatStore = useChatStore();
  const meetStore = useMeetStore();
  const { channel, createChannel, destroyChannel } = usePusherChannel(
    computed(() => (roomStore.roomId ? ROOM_CHANNEL_KEY + '.' + roomStore.roomId : null)),
    ROOM_CHANNEL_KEY
  );

  usePusherEvent(channel, EVENTS.RoomMessageCreated, message => {
    chatStore.addNewMessageWithSocket(message);
    if (meetStore.isConnectedMeet) {
      meetStore.handleAddNewMessage(message);
    }
  });
  usePusherEvent(channel, EVENTS.RoomMessageUpdate, message => {
    chatStore.updateMessageData(message);
  });
  usePusherEvent(channel, EVENTS.RoomMessageDelete, message => {
    chatStore.deleteMessage(message.id);
  });

  watch(
    () => roomStore.roomId,
    val => {
      if (val) {
        createChannel();
        return;
      }
      destroyChannel();
    },
    {
      immediate: true
    }
  );
};

export { subscribeUserNotificationChannel, subscribeRoomNotificationChannel };
