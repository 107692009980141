/**
 * Creating a wrapper for promises on a specific time interval.
 *
 * @param {number} milliseconds - The number of milliseconds to wait the specified
 * {@code promise} to settle before automatically rejecting the returned
 * {@code Promise}.
 * @param {Promise} promise - The {@code Promise} for which automatic rejecting
 * after the specified timeout is to be implemented.
 * @returns {Promise}
 */
export function timeout(milliseconds: number, promise: Promise<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('408'));

      return;
    }, milliseconds);

    promise.then(resolve, reject);
  });
}

export async function loadScript(scriptPath: string) {
  return new Promise((resolve, reject) => {
    const scriptId = useKebabCase(scriptPath);
    let script = document.getElementById(scriptId) as HTMLScriptElement | null;
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.src = scriptPath;
      script.onload = () => {
        resolve(script);
      };
      script.onerror = reject;
      document.body.appendChild(script);
      return;
    }
    resolve(script);
  });
}
