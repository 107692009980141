import type { KFilesData, FilesDataItem } from '~/globals/FilesData';

type FileExtByTypes = {
  extensions: string[];
  accepts: string[];
};
const getFileNameByPath = (pathFile = '') => pathFile.replace(/^.*[\\/]/, '');

const getExtByTypes = (accepts: string[] = []) => {
  return Object.values(FilesData)
    .flat()
    .filter(val => accepts.includes(val.accept))
    .map(({ ext }) => ext);
};
const getFileExtByTypes = (types: KFilesData[] = []) => {
  const defaultValue: FileExtByTypes = {
    extensions: [],
    accepts: []
  };

  const fileTypes = !types?.length
    ? Object.values(FilesData)
    : useFilter(FilesData, (_, key) => types.includes(key as KFilesData));

  return fileTypes.flat().reduce<FileExtByTypes>((acc, val) => {
    acc.extensions.push(val.ext);
    acc.accepts.push(val.accept);
    return acc;
  }, defaultValue);
};

const isImage = (type: KFilesData) => useIncludes(['otherImage', 'img'], type);

const getFileExtension = (fileName?: string) => {
  return useSplit(fileName, '.').pop();
};

const getFileTypeByName = (fileName?: string) => {
  const currentExt = getFileExtension(fileName);
  const type = useFindKey(FilesData, items => useSome(items, (item: FilesDataItem) => item.ext === currentExt));
  if (!type) {
    return 'other';
  }
  return (isImage(type as KFilesData) ? 'img' : type) as KFilesData;
};

const stringToTypedArray = (string: string) => {
  const stringLength = string?.length;
  const arrayBuffer = new ArrayBuffer(stringLength);
  const view = new Uint8Array(arrayBuffer);

  for (let i = 0; i < stringLength; i += 1) {
    view[i] = string.charCodeAt(i);
  }

  return view;
};

const base64ToBlob = (string: string, type = 'image/png') => {
  return new Blob([stringToTypedArray(window.atob(string))], { type });
};
const dataURIToBlob = (string: string) => {
  const [header, base64] = string.split(',');

  return base64ToBlob(base64, header.replace('data:', '').replace(';base64', ''));
};

const getFileExternalType = (type: string) => {
  if (/video\/*/.test(type)) {
    return 'video';
  }
  if (/image\/*/.test(type)) {
    return 'img';
  }
  return null;
};

const generatePreviewLink = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', function () {
      resolve(fileReader.result);
    });
    fileReader.readAsDataURL(file);
  });
};

const imgTypes = getFileExtByTypes(['img']);
const allTypes = getFileExtByTypes();
const docsAndSheetsAndPdfExtAccept = getFileExtByTypes(['docs', 'sheets', 'pdf']);

const getProxyStorageUrl = (path: string) => {
  const url = new URL(path);
  return url.origin === window.location.origin ? path : '/api' + url.pathname;
};

const getFileFromUrl = async (path: string, defaultType = 'image/png') => {
  const response = await fetch(getProxyStorageUrl(path), {
    cache: 'force-cache'
  });
  const data = await response.blob();
  return new File([data], getFileNameByPath(path), {
    type: data.type || defaultType
  });
};

const downloadStorageFile = (path: string, fillName?: string) => {
  const link = document.createElement('a');
  link.href = getProxyStorageUrl(path);
  link.download = fillName || getFileNameByPath(path);
  link.click();
};

const blobToData = (blob: Blob) =>
  new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result?.toString());
    reader.readAsDataURL(blob);
  });

const toDataURL = async (url: string) => {
  const response = await fetch(url, { cache: 'force-cache' });
  const blob = await response.blob();
  return await blobToData(blob);
};

export {
  getFileExtByTypes,
  getFileTypeByName,
  getFileNameByPath,
  dataURIToBlob,
  getFileExternalType,
  generatePreviewLink,
  getExtByTypes,
  isImage,
  getFileFromUrl,
  downloadStorageFile,
  getFileExtension,
  toDataURL,
  imgTypes,
  allTypes,
  docsAndSheetsAndPdfExtAccept
};
