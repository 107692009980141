import { InterestableType, type TInterestableType } from '~/globals/interest';

type InterestsStateType = {
  [key in TInterestableType]: Interest[];
};
const makeInterestsState = (): InterestsStateType => {
  return {
    [InterestableType.USER]: [],
    [InterestableType.ROOM]: []
  };
};

const useInterestsStore = defineStore('interests-store', () => {
  const apiRoutes = useApiRoutes();
  const defaultInterests = ref<Interest[]>([]);
  const privateInterests = reactive(makeInterestsState());
  const interestIds = computed(() => {
    return useMap(defaultInterests.value, (interest: Interest) => interest.id);
  });

  const getPrivateInterests = (type: TInterestableType) => {
    const interests = useMap<Interest, Interest>(privateInterests[type], item => ({
      ...item,
      is_private: !interestIds.value.includes(item.id)
    }));

    return [...interests, ...defaultInterests.value];
  };
  const profilePrivateInterests = computed(() => getPrivateInterests(InterestableType.USER));
  const roomPrivateInterests = computed(() => getPrivateInterests(InterestableType.ROOM));
  const fetchRoomInterests = async (id: number) => {
    const res = await apiRoutes.rooms.getPrivateInterests(id);
    privateInterests[InterestableType.ROOM] = res?._data.data;
    return true;
  };
  const fetchProfileInterests = async () => {
    const res = await apiRoutes.profile.getPrivateInterests();
    privateInterests[InterestableType.USER] = res?._data.data;

    return true;
  };
  const fetchDefaultInterests = async (force = false) => {
    if (defaultInterests.value.length && !force) {
      return;
    }
    const res = await apiRoutes.interests.index();
    defaultInterests.value = res._data.data;
    return true;
  };

  const onAddInterest = (interest: Interest, type: TInterestableType) => {
    privateInterests[type].unshift(interest);
  };

  const onDeleteInterest = (id: number, type: TInterestableType) => {
    privateInterests[type] = useFilter<Interest>(privateInterests[type], v => v.id !== id) as Interest[];
  };

  const clearData = (type: TInterestableType) => {
    privateInterests[type] = [];
  };

  return {
    defaultInterests,
    privateInterests,
    roomPrivateInterests,
    profilePrivateInterests,
    clearData,
    onAddInterest,
    onDeleteInterest,
    fetchRoomInterests,
    fetchProfileInterests,
    fetchDefaultInterests
  };
});

export default useInterestsStore;
