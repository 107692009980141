<template>
  <div>
    <div
      class="flex gap-2.5 items-center"
      :class="[{ 'flex-col text-center': col, 'field-error': !!error }, filedWrapClass]"
    >
      <slot :id="computedId" />

      <slot
        :id="computedId"
        name="label"
        :error="error"
      >
        <ui-fields-label
          v-if="label"
          :for="computedId"
          :text="label"
          :required="required"
          :error="!!error"
          control
        />
      </slot>
    </div>
    <div
      v-if="showError"
      class="md:pl-8 pl-5"
    >
      <ui-typography
        variant="small"
        class="mb-1.5 text-error animate-shake"
      >
        {{ error }}
      </ui-typography>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<ControlWrap>();
  const ssrId = useId();
  const computedId = computed(() => (props.inputId ? props.inputId : ssrId));
  const showError = computed(() => !props.hideError && !!props.error);
</script>
