<template>
  <ui-fields-wrap
    :label="label"
    :label-two="labelTwo"
    :icon-right="iconRight"
    :icon-left="iconLeft"
    :on-click-right-icon="onClickRightIcon"
    :on-click-left-icon="onClickLeftIcon"
    :required="required"
    :input-id="inputId"
    :hint="hint"
    :has-value="hasValue"
    :disabled="disabled"
    :error="errorMessage"
    :autofocus="autofocus"
    :filed-wrap-class="filedWrapClass"
    :input-wrap-class="inputWrapClass"
    :placeholder="placeholder"
    :on-click-close="onClear"
    :loading="loading"
    :show-clear="showClear && hasValue"
  >
    <template #default="{ inputId, setRef, inputClasses, onFocus, onBlur }">
      <input
        :id="inputId"
        :ref="setRef"
        :value="value"
        :type="type"
        :name="name"
        :readonly="loading"
        :disabled="disabled"
        :placeholder="placeholder || ''"
        :class="inputClasses"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @change="onChange"
      />
    </template>
    <template
      v-for="(_, slotName) in $slots"
      #[slotName]="slotData"
    >
      <slot
        :name="slotName"
        v-bind="slotData"
      />
    </template>
  </ui-fields-wrap>
</template>

<script setup lang="ts">
  defineOptions({
    inheritAttrs: false
  });

  const props = withDefaults(defineProps<TextFieldProps>(), {
    name: props => props.name || '',
    type: 'text'
  });
  const emit = defineEmits<TextFiledEmit>();

  const { value, errorMessage, hasValue, onChange, onInput, onClear } = useTextField(props, emit);
</script>
