<template>
  <div class="flex gap-3 items-center">
    <ui-avatar
      v-if="user"
      class="shrink-0"
      size="sm"
      :user-name="user.name"
      :src="user.avatar"
    />
    <ui-typography
      v-if="message"
      class="flex-1"
    >
      <i18n-t
        :i18n="i18n"
        :keypath="message"
        scope="global"
      >
        <template #user>
          <span class="text-primary">{{ user?.name }}</span>
        </template>
        <template
          v-for="(val, key) in tData"
          :key="key"
          #[key]
        >
          <span class="text-primary">{{ val }}</span>
        </template>
      </i18n-t>
    </ui-typography>
    <slot />
  </div>
</template>

<script setup lang="ts">
  import { I18nT } from 'vue-i18n';
  defineProps<{ user?: UserMeet; message?: string; tData?: Record<string, any> }>();
  const i18n = useNuxtApp().$i18n;
</script>
