import { UiToastLimitSubscriptions } from '#components';
const useLimitToasts = () => {
  const toast = useToast();
  const getMessageErrorByResponse = useGetMessageErrorByResponse();
  const scrollToFirstError = useScrollToFirstError();

  return (error: any) => {
    const status = error?.response?.status || error?.statusCode;
    switch (status) {
      case 403: {
        toast.error(h(UiToastLimitSubscriptions));
        return;
      }
      case 422: {
        scrollToFirstError();
        return;
      }
      default: {
        toast.error(getMessageErrorByResponse(error));
      }
    }
  };
};

export { useLimitToasts };
