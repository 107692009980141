import type JitsiLocalTrack from 'modules/RTC/JitsiLocalTrack';
import { createVirtualBackgroundEffect } from '~/utils-client/jitsi/virtual-background/createVirtualBackgroundEffect';
import { NoiseSuppressionEffect } from '~/utils-client/jitsi/noise-suppression/NoiseSuppressionEffect';

const setJitsiVideoEffect = async (track?: JitsiLocalTrack | null, options?: SetVideoEffectOptions) => {
  if (!track) {
    return;
  }
  if (VirtualBackgroundType.IMAGE === options?.backgroundType) {
    if (options.imgUrl) {
      const url = (await toDataURL(options.imgUrl)) as string;
      await track.setEffect(
        await createVirtualBackgroundEffect({
          virtualSource: url,
          backgroundType: VirtualBackgroundType.IMAGE
        })
      );
      return;
    }
    if (options.virtualSource) {
      await track.setEffect(
        await createVirtualBackgroundEffect({
          virtualSource: options.virtualSource,
          backgroundType: VirtualBackgroundType.IMAGE
        })
      );
      return;
    }
  } else if (VirtualBackgroundType.BLUR === options?.backgroundType) {
    await track.setEffect(
      await createVirtualBackgroundEffect({
        blurValue: options.blurValue,
        backgroundType: VirtualBackgroundType.BLUR
      })
    );
    return;
  }
  await track.setEffect(undefined);
};

const setJitsiNoiseEffect = async (track?: JitsiLocalTrack | null, enabled?: boolean) => {
  if (!track) {
    return;
  }
  if (enabled) {
    await track.setEffect(new NoiseSuppressionEffect());
    return;
  }
  await track.setEffect(undefined);
};

export { setJitsiVideoEffect, setJitsiNoiseEffect };
