import { StorageSerializers } from '@vueuse/core';
import type { AsyncDataOptions, NuxtApp } from '#app';
import type { FetchInstance } from '@refactorjs/ofetch';

const useApiRoutes = () => {
  return useNuxtApp().$apiRoutes as ApiRequests;
};

const useApi = () => {
  return useNuxtApp().$api as FetchInstance;
};

const useGetApiRequests: PropFunction = (props, key) => {
  const apiRoutes = useApiRoutes();
  const requestPath = useProp<ApiRequestsPath>(props, key);
  return computed(() => useGet<ApiRequests, ApiRequestsPath, () => null>(apiRoutes, requestPath.value, () => null));
};

const useCacheAsyncData = async function <TData = any>(
  key: string,
  handler: (ctx?: NuxtApp) => Promise<any>,
  options?: AsyncDataOptions<any, any, any, any>,
  isSession = false
): Promise<CacheAsyncData> {
  let data: Ref<TData>;
  const defaultOptions = () => {
    return (options?.default?.() || null) as TData;
  };
  if (isSession) {
    data = useSessionStorage<TData>(key, defaultOptions(), {
      serializer: StorageSerializers.object
    });
  } else {
    data = useState<TData>(key, defaultOptions);
  }
  let isRefresh = false;
  const isDataCache = computed(() => !isEqual(data.value, options?.default?.()));

  const asyncDataState = await useLazyAsyncData(
    key,
    async ctx => {
      if (isEmpty(data.value) || !isDataCache.value || isRefresh) {
        const res = await handler(ctx);
        data.value = options?.transform ? options.transform(res) : res;
      }
      return data.value;
    },
    options
  );

  return {
    error: asyncDataState.error,
    pending: asyncDataState.pending,
    data,
    refresh: async () => {
      isRefresh = true;
      await asyncDataState.refresh();
      isRefresh = false;
    }
  };
};

export { useApi, useApiRoutes, useGetApiRequests, useCacheAsyncData };
