const SymbolProfileLayout = Symbol('profileLayout');
const makeLayoutWrap = () => {
  const contentWrap = ref<HTMLElement>();
  const onResetScroll = () => {
    if (contentWrap.value) {
      contentWrap.value.scrollTop = 0;
    }
  };

  const state = {
    contentWrap,
    onResetScroll
  };

  provide(SymbolProfileLayout, state);

  return state;
};

const useLayoutWrap = () => {
  const state = inject<ReturnType<typeof makeLayoutWrap>>(SymbolProfileLayout);
  if (!state) {
    throw new Error(`Could not find useLayoutWrap injection with symbol ${SymbolProfileLayout.description}`);
  }
  return state;
};

export { useLayoutWrap, makeLayoutWrap };
