import { useRoute } from '#app';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

const useAddCookieAuthRedirect = () => {
  const cookie = useAuthRedirectCookie();

  return (_route?: RouteLocationNormalizedLoaded) => {
    const currentRoute = _route || useRoute();
    cookie.value = currentRoute.fullPath;
  };
};

export default useAddCookieAuthRedirect;
