<template>
  <common-jitsi-toast-base-notify message="JITSI.NOTIFY.ASK_TO_UNMUTE">
    <ui-button @click="onAccept">
      {{ t('Unmute') }}
    </ui-button>
  </common-jitsi-toast-base-notify>
</template>

<script setup lang="ts">
  const emit = defineEmits(['close']);
  const { t } = useNuxtApp().$i18n;
  const meetStore = useMeetStore();
  const onAccept = () => {
    meetStore.toggleLocalAudio(true);
    emit('close');
  };
</script>
