import { cva, type VariantProps } from 'class-variance-authority';

const controlVariants = cva(
  'flex select-none items-center justify-center border aria-disabled:!bg-disabled aria-disabled:!text-disabled aria-disabled:peer-checked:!text-white aria-disabled:!border-disabled  border-input-active-border hover:text-input-text focus:text-primary',
  {
    variants: {
      variant: {
        base: 'text-disabled peer-checked:border-input-active-border peer-checked:bg-input-active-border peer-checked:text-white bg-input-bg',
        second:
          'text-purple-200 peer-checked:text-white peer-checked:bg-primary peer-checked:border-primary aria-disabled:peer-checked:bg-white text-sm'
      },
      size: {
        sm: 'w-4 h-4',
        md: 'w-6 h-6'
      },
      rounded: {
        base: 'rounded',
        full: 'rounded-full'
      }
    }
  }
);
type Props = VariantProps<typeof controlVariants>;

type ControlCssProps = {
  variant?: Props['variant'];
  size?: Props['size'];
  rounded?: Props['rounded'];
};

export { type ControlCssProps, controlVariants };
