<template>
  <slot
    v-if="error"
    name="error"
    :error="error"
    :clear-error="resetError"
  >
    <ui-error-boundary-message
      :error="error"
      :is-page="isPage"
      :class="classes"
      @clear-error="resetError"
    />
  </slot>
  <slot v-else />
</template>
<script setup lang="ts">
  type Props = {
    error: any;
    isPage?: boolean;
    classes?: ClassesType;
  };
  type Emits = {
    (e: 'error', value: any): boolean;
  };
  const props = defineProps<Props>();
  const emit = defineEmits<Emits>();

  const error = ref(props.error);
  const nuxtApp = useNuxtApp();
  const route = useRoute();
  onErrorCaptured((err, target, info) => {
    if (import.meta.client && !nuxtApp.isHydrating) {
      emit('error', err);
      nuxtApp.hooks.callHook('vue:error', err, target, info);
      error.value = err;
      return false;
    }
  });

  function resetError() {
    error.value = null;
  }

  watch(
    () => props.error,
    () => {
      error.value = props.error;
    },
    {
      immediate: true
    }
  );

  watch(
    () => route.path,
    () => {
      clearError();
      resetError();
    }
  );
</script>
