<template>
  <ui-fields-text
    :name="name"
    is-required
    :filed-wrap-class="filedWrapClass"
    :label="label"
    :icon-right="show ? 'visibility' : 'visibility_off'"
    :on-click-right-icon="() => toggleShow()"
    :type="show ? 'text' : 'password'"
  />
</template>

<script setup lang="ts">
  type Props = {
    name: string;
    label?: string;
    filedWrapClass?: ClassesType;
  };
  defineProps<Props>();
  const [show, toggleShow] = useToggle();
</script>
