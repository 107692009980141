<template>
  <ui-fields-text
    v-model="innerModel"
    :placeholder="placeholder"
    :sync-v-model="syncVModel"
    :filed-wrap-class="filedWrapClass"
    :input-wrap-class="inputWrapClass"
    :enabled-controlled="enabledControlled"
    icon-right="search"
  />
</template>

<script setup lang="ts">
  const props = defineProps<TextFieldProps>();
  const innerModel = useVModel(props, 'modelValue');
</script>
