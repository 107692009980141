<template>
  <div
    :ref="setDropWrap"
    class="relative flex gap-5 items-center flex-wrap"
  >
    <slot
      :input-id="computedId"
      :loading="loading"
      :choose-disabled="chooseDisabled"
    >
      <ui-button
        size="md"
        :for="computedId"
        :loading="loading"
        tag="label"
        variant="primary"
        :text="btnAddText"
      />
    </slot>
    <slot
      v-if="!hidePreview"
      name="files"
      :input-id="computedId"
      :inner-disabled="chooseDisabled"
      :files="files"
    >
      <div
        v-if="multiple"
        class="grid grid-cols-1 gap-1.5 shrink-0"
      >
        <ui-fields-file-content
          v-for="(file, index) in files"
          :key="file.name + file.type + file.size"
          :file="file"
          :disabled="disabled"
          :hide-remove="hideRemove"
          @remove="onRemoveFile(index)"
        />
      </div>
      <ui-fields-file-content
        v-else
        :file="files[0]"
        class="shrink-0"
        :init-url="initUrl"
        :input-id="computedId"
        :disabled="disabled"
        :hide-remove="hideRemove"
        @remove="onRemoveFile(0)"
      />
    </slot>
    <ui-overlay
      v-if="!disabledDrop"
      v-show="isDrop"
      class="text-white rounded-xl z-1"
    >
      <ui-icon
        name="add_circle"
        class="text-primary relative text-3xl"
      />
    </ui-overlay>
    <input
      :id="computedId"
      ref="fileInput"
      class="hidden"
      type="file"
      :accept="prepareAccept"
      :disabled="chooseDisabled"
      :multiple="multiple"
      @change="onFileSelect"
    />
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(defineProps<UploadComponent>(), {
    accept: () => imgTypes.accepts,
    maxFileSize: MaxFileUploadSize,
    btnAddText: 'Add Image'
  });
  const emit = defineEmits(['select', 'remove']);
  const ssrId = useId();
  const computedId = computed(() => (props.inputId ? props.inputId : ssrId));
  const { files, loading, chooseDisabled, prepareAccept, fileInput, isDrop, setDropWrap, onRemoveFile, onFileSelect } =
    useValidateFile(props, {
      onSelect: files => emit('select', files),
      onRemove: data => emit('remove', data)
    });
</script>
