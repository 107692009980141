<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="250"
    height="250"
    fill="none"
    viewBox="0 0 250 250"
  >
    <rect
      width="250"
      height="250"
      class="fill-card-placeholder-border"
      rx="5"
    />
    <path
      class="fill-card"
      d="M42.5 230c-6.188 0-11.484-2.24-15.89-6.719-4.407-4.479-6.61-9.739-6.61-15.781v-165c0-6.042 2.203-11.302 6.61-15.781C31.015 22.239 36.312 20 42.5 20h165c6.187 0 11.484 2.24 15.891 6.719C227.797 31.198 230 36.459 230 42.5v165c0 6.042-2.203 11.302-6.609 15.781-4.407 4.479-9.704 6.719-15.891 6.719h-165Zm0-22.5h165v-165h-165v165Zm15-22.5h135l-45-60-33.75 45-22.5-30-33.75 45Z"
    />
  </svg>
</template>
