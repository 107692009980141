import { useStorage } from '@vueuse/core';
type Options = {
  immediate?: boolean;
};

const DEFAULT_TIMER = 120;

const useDownTimerStorage = (key: string, options: Options = {}) => {
  const { immediate } = {
    immediate: true,
    ...options
  };
  const { APP_NAME } = useRuntimeConfig().public;
  const timestampLastSend = useStorage(APP_NAME + '___' + key, 0);
  const dayjs = useDayjs();
  const diffTimeLastSend = computed(() => {
    const diffTime = timestampLastSend.value && dayjs.unix(timestampLastSend.value).diff(dayjs(), 's');
    return diffTime && diffTime > 0 ? diffTime : 0;
  });

  const { formatTime, isActive, start } = useDownTimer(diffTimeLastSend);

  const addTimestamp = (timeSec = DEFAULT_TIMER) => {
    timestampLastSend.value = dayjs().add(timeSec, 's').unix();
  };

  watch(
    diffTimeLastSend,
    (val: number) => {
      if (val) {
        start();
        return;
      }
      timestampLastSend.value = 0;
    },
    {
      immediate
    }
  );

  return {
    addTimestamp,
    formatTime,
    isActive
  };
};

export default useDownTimerStorage;
