<template>
  <common-jitsi-toast-base-notify
    :user="user"
    message="JITSI.NOTIFY.JOIN_LOBBY"
  >
    <ui-button
      icon-left="check"
      size="lg-icon"
      @click="onAccept"
    />
    <ui-button
      icon-left="close"
      size="lg-icon"
      variant="error"
      @click="onDecline"
    />
  </common-jitsi-toast-base-notify>
</template>

<script setup lang="ts">
  const props = defineProps<{ user: UserMeet; userId: string }>();
  const emit = defineEmits(['close']);
  const meetStore = useMeetStore();
  const onClose = () => {
    emit('close');
  };
  const onAccept = () => {
    meetStore.onLobbyApproveAccess(props.userId);
    onClose();
  };

  const onDecline = () => {
    meetStore.onLobbyDenyAccess(props.userId);
    onClose();
  };
</script>
