export default defineNuxtRouteMiddleware(to => {
  const { isAuthenticated } = useSanctumAuth<User>();

  if (isAuthenticated.value) {
    const localePath = useLocalePath();
    const redirect = to.meta.redirectTo || {
      name: 'dashboard-settings-privacy-policy'
    };
    return navigateTo(localePath(redirect));
  }
});
