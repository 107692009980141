<template>
  <ui-fields-control-wrap
    :input-id="inputId"
    :filed-wrap-class="filedWrapClass"
    :error="errorMessage"
    :required="required"
    :label="label"
    :col="col"
    :disabled="disabled"
    :hide-error="hideError"
  >
    <template #default="{ id }">
      <div :class="controlWrapClass">
        <input
          :id="id"
          :value="checkedValue"
          class="sr-only h-0 peer"
          :checked="!!checked"
          :type="type"
          :disabled="disabled"
          @change="handleChange"
        />
        <slot
          :input-id="id"
          name="control"
          :checked="checked"
        >
          <label
            :for="id"
            :class="classes"
            :aria-disabled="disabled"
          >
            <span
              v-if="iconRadio"
              class="w-[calc(100%-10px)] h-[calc(100%-10px)] rounded-full bg-current block"
            />
            <ui-icon
              v-else
              name="check"
            />
          </label>
        </slot>
      </div>
    </template>
    <template #label="{ id }">
      <slot
        name="label"
        :input-id="id"
      />
    </template>
  </ui-fields-control-wrap>
</template>

<script setup lang="ts">
  import { type ControlCssProps, controlVariants } from '~/components/ui/fields/Control/styles';

  type Props = ControlField & ControlCssProps & { controlWrapClass?: ClassesType };

  const props = withDefaults(defineProps<Props>(), {
    checkedValue: '1',
    uncheckedValue: '0',
    type: 'checkbox',
    variant: 'base',
    rounded: 'base',
    size: 'md'
  });

  const { handleChange, checked, errorMessage } = useField(() => props.name || '', undefined, {
    type: props.type,
    initialValue: props.modelValue,
    checkedValue: props.checkedValue,
    uncheckedValue: props.uncheckedValue,
    syncVModel: props.syncVModel,
    controlled: !props.enabledControlled,
    keepValueOnUnmount: () => props.keepValueOnUnmount
  });

  const classes = computed(() => controlVariants(props));
</script>
