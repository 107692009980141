<template>
  <div class="flex gap-1 items-center max-w-56">
    <ui-typography
      v-if="fileName"
      variant="caption"
      class="truncate"
    >
      {{ fileName }}
    </ui-typography>
    <ui-button
      v-if="file && !hideRemove"
      icon-left="delete"
      variant="error"
      size="xs-icon"
      rounded="sm"
      class="shrink-0"
      @click="onRemove"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    file?: any;
    initUrl?: string;
    inputId?: string;
    hideRemove?: boolean;
  };
  const props = withDefaults(defineProps<Props>(), {});
  const emit = defineEmits(['remove']);
  const fileName = computed(() => {
    if (props.file?.name) {
      return props.file?.name;
    }
    if (props.initUrl) {
      return getFileNameByPath(props.initUrl);
    }
    return undefined;
  });

  const onRemove = () => {
    emit('remove');
  };
</script>
