const MessagesError = {
  unauthorized: 'Unauthenticated.',
  unauthenticated: 'You have been logged out due to inactivity or an alternate login.',
  forbidden: 'Access Denied: You do not have permission to access this resource',
  resourceNotFound: 'Resource not found!',
  verificationInvalidToken: 'Verification token is invalid!',
  somethingWentWrong: 'Something went wrong. Please try again later.',
  isRequired: `This field {name} is required!`,
  invalidEmail: 'Please enter a valid email address.',
  invalidPhone: 'Please enter a valid phone number.',
  charactersMin: 'Please enter at least {min} characters',
  charactersMax: 'Please enter at no more than {max} characters',
  itemMin: 'Please add at least a {min} item',
  itemMax: 'Maximum number of items {max}',
  confirmedPass: 'Please enter the same password again.',
  invalidFileType: '{name}: Invalid file type, allowed file types: {types}.',
  invalidFileSize: '{name}: Invalid file size, file size should be smaller than: {size}.',
  invalidFileLimit: 'Maximum number of files exceeded, limit is {max} at most.',
  alphaDash: 'This field may contain alphabetic characters, numbers, dashes or underscores. No spaces.',
  url: 'URL is not valid',
  interestCreateLimit: "You've hit the limit for custom interests.",
  invalidSelectPermissions: 'Please select at least one rule',
  messageNotFound: 'Message not found',
  meetAuthError: 'Sorry! You are not allowed to be here :(',
  meetVideoError: 'Video render error',
  'conference.connectionError.accessDenied': 'Your join request was rejected by a moderator.'
} as const;

const MessagesSuccess = {
  forgotPassword: 'We have sent you a link to confirm your email! Check your inbox.',
  updatePassword: 'Your password has been updated',
  updateProfile: 'Your social profiles have been updated'
} as const;

type TMessagesError = (typeof MessagesError)[keyof typeof MessagesError];

const MessagesNotification = {
  roomRequestApproved: 'Your request to join the {room} was accepted by the administrator.',
  roomRequestRejected: 'Your request to join the {room} was rejected by the administrator.',
  mentionedInRoomMessage: 'You have been mentioned by {author} in {mention}',
  repliedInRoomMessage: 'The {author} replied to your message in {mention}',
  roomTypeChanged: 'The {room} status has been updated',
  roomRequestCreated: '{author} sent request to join {room}'
} as const;

export { MessagesError, MessagesSuccess, MessagesNotification, type TMessagesError };
