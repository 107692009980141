<template>
  <span :class="wrapClasses">
    <img
      v-show="!isShowPlaceholder"
      ref="image"
      :class="classes"
      :src="src"
      :alt="alt || 'image'"
      loading="lazy"
      @error="onError"
      @click="$emit('click')"
    />
    <ui-placeholder-image
      v-if="isShowPlaceholder"
      :class="classes"
    />
  </span>
</template>

<script setup lang="ts">
  defineOptions({
    inheritAttrs: false
  });
  type Props = {
    src?: string;
    alt?: string;
    placeholderClass?: ClassesType;
    wrapClass?: ClassesType;
  };
  defineEmits(['click']);
  const props = defineProps<Props>();

  const image = ref<HTMLImageElement>();
  const isShowPlaceholder = ref<boolean>(!props.src);

  const onError = () => {
    isShowPlaceholder.value = true;
  };

  const attrs = useAttrs();
  const classes = computed(() => {
    return cn(attrs.class as string, isShowPlaceholder.value && props.placeholderClass);
  });

  const wrapClasses = computed(() => cn('w-full h-full inline-flex flex-1', props.wrapClass));

  watch(
    () => props.src,
    val => {
      isShowPlaceholder.value = !val;
    }
  );

  onMounted(() => {
    const element = image.value;

    nextTick(() => {
      if (!element || !element.complete) return;
      if (element.naturalWidth === 0) {
        onError();
      }
    });
  });
</script>
