<template>
  <ui-fields-wrap
    :label-two="label"
    :required="required"
    :input-id="inputId"
    :disabled="disabled"
    :error="errorMessage"
    wrap-class="border-none bg-transparent p-0 md:p-0"
    input-wrap-class="p-0 md:p-0 max-w-full"
  >
    <ui-fields-file-upload
      class="flex-1 overflow-hidden"
      :hide-preview="hidePreview"
      :name="name"
      :accept="accept"
      :multiple="multiple"
      :class="inputClass"
      :max-file-size="maxFileSize"
      :file-limit="fileLimit"
      :upload="upload"
      :input-id="inputId"
      :hide-remove="hideRemove"
      :btn-add-text="btnAddText"
      :disabled="disabled"
      @select="onSelectFile"
    >
      <template #default="slotData">
        <slot v-bind="slotData" />
      </template>
      <template #files="slotData">
        <slot
          name="files"
          v-bind="slotData"
        />
      </template>
    </ui-fields-file-upload>
  </ui-fields-wrap>
</template>

<script setup lang="ts">
  import { useField } from 'vee-validate';
  type Props = Omit<BaseField, 'labelTwo'> & {
    name: string;
    inputClass?: ClassesType;
  } & Omit<UploadComponent, 'initUrl'>;

  const props = defineProps<Props>();
  const emit = defineEmits(['select']);

  const { setValue, errorMessage } = useField(
    computed(() => props.name),
    undefined
  );

  const onSelectFile = (files: File[]) => {
    emit('select', files);
    setValue(files);
  };
</script>
