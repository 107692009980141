const useChatAttachmentsStore = defineStore('ChatAttachments', () => {
  const apiRoutes = useApiRoutes();
  const roomStore = useRoomStore();
  const chatStore = useChatStore();
  const handleRequest = useHandleRequest();

  const loading = reactive({
    prev: false,
    next: false,
    global: false
  });
  const attachments = ref<MessageAttachment[]>([]);
  const attachmentMeta = reactive<AdvancedPaginationMeta>({
    prev: {},
    next: {},
    search: {
      prev: {},
      next: {}
    }
  });

  const hasPrev = computed(() => !!attachmentMeta.prev.next_cursor);
  const hasNext = computed(() => !!attachmentMeta.next.next_cursor);
  const addAuthorToAttachment = (message: Message) =>
    useMap(message.attachments, attachment => {
      return {
        ...attachment,
        author: message.author
      };
    });

  const onUpdateMessageAttachments = (message: Message) => {
    const newAttachments = addAuthorToAttachment(message);
    const filterAttachments = useFilter(attachments.value, attachment => attachment.message_id !== message.id);
    filterAttachments.push(
      ...useFilter(newAttachments, attachment => useIncludes(['img', 'video'], getFileTypeByName(attachment.name)))
    );

    attachments.value = useOrderBy(filterAttachments, ['message_id', 'id'], 'asc');
  };

  const removeAttachments = (idx: number[]) => {
    attachments.value = removeItemsArray(attachments, idx);
  };

  const fetchAttachments = async (params: FetchMessagesParams = {}) => {
    if (roomStore.disableFetchMessages) {
      return false;
    }
    const res = await handleRequest(
      apiRoutes.rooms.getMessages(roomStore.roomId, {
        ...params,
        attachments_type: 'media'
      })
    );
    if (!res.isError) {
      const { meta, data } = res.data;
      return {
        meta,
        data: useMap(data, addAuthorToAttachment).flat()
      };
    }
    return false;
  };

  const findActiveAttachmentIdx = (attachmentId: number) => {
    return useFindIndex(attachments.value, { id: attachmentId });
  };

  const reset = () => {
    attachmentMeta.prev = {};
    attachmentMeta.next = {};
    attachmentMeta.search = {
      prev: {},
      next: {}
    };
    loading.prev = false;
    loading.next = false;
    loading.global = false;
    attachments.value = [];
  };

  const goToAttachment = async (attachment: MessageAttachment, force = false) => {
    const attachmentId = attachment.id;
    const activeIdx = findActiveAttachmentIdx(attachmentId);
    if (activeIdx < 0 || force) {
      if (!roomStore.disableFetchMessages) {
        reset();
        loading.global = true;
        const nextParams = {
          to_id: attachment.message_id
        };
        const nextRes = await fetchAttachments(nextParams);
        if (nextRes) {
          attachmentMeta.next = nextRes.meta;
          attachments.value = nextRes.data;
          attachmentMeta.search.next = nextParams;
          const lastMessageId = attachments.value?.[0]?.message_id;
          if (attachments.value.length) {
            const prevParams = {
              before_id: lastMessageId
            };
            const prevRes = await fetchAttachments(prevParams);
            if (prevRes) {
              attachmentMeta.prev = prevRes.meta;
              attachments.value.unshift(...prevRes.data.reverse());
              attachmentMeta.search.prev = prevParams;
            }
          }
        }
        loading.global = false;
        return findActiveAttachmentIdx(attachmentId);
      }
      attachments.value = useMap(chatStore.messages, 'attachments').flat();
      return findActiveAttachmentIdx(attachmentId);
    }
    return activeIdx;
  };

  const onLoadAttachment = async (direct: 'prev' | 'next' = 'prev') => {
    const cursor = attachmentMeta[direct].next_cursor;
    if (!cursor || loading[direct]) {
      return false;
    }
    loading[direct] = true;
    const res = await fetchAttachments({
      ...attachmentMeta.search[direct],
      cursor
    });
    if (res) {
      attachmentMeta[direct] = res.meta;
      const message = res.data;
      if (direct === 'prev') {
        attachments.value.unshift(...message.reverse());
      } else {
        attachments.value.push(...message);
      }
    }

    loading[direct] = false;
    return true;
  };

  return {
    loading,
    attachments,
    hasPrev,
    hasNext,

    onLoadAttachment,
    goToAttachment,
    removeAttachments,
    onUpdateMessageAttachments,
    reset
  };
});

export default useChatAttachmentsStore;
