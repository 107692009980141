import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, deleteToken, isSupported } from 'firebase/messaging';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { UiToastUpdateApp } from '#components';

export default defineNuxtPlugin(() => {
  const init = async () => {
    const toast = useToast();
    const { isAuthenticated } = useSanctumAuth();
    const config = useRuntimeConfig().public;
    const apiRoutes = useApiRoutes();
    const handleRequest = useHandleRequest();
    const savedToken = useLocalStorage<string | undefined>(config.APP_NAME + '__push-token', '');
    const swRegister =
      config.DEV_PWA || !config.IS_DEV
        ? await new Promise<ServiceWorkerRegistration | undefined>(resolve => {
            useRegisterSW({
              immediate: true,
              onRegisteredSW(_swScriptUrl, registration) {
                resolve(registration);
              },
              onNeedRefresh() {
                toast.info(h(UiToastUpdateApp), {
                  autoClose: false,
                  closeButton: false,
                  toastId: 'upgradeApp'
                });
              }
            });
          })
        : undefined;

    if (!(await isSupported()) || !window?.Notification || !swRegister || !config.FIREBASE_API_KEY) {
      return;
    }
    if (Notification.permission === 'default') {
      await Notification.requestPermission();
    }
    if (Notification.permission === 'denied') {
      return;
    }

    const app = initializeApp({
      apiKey: config.FIREBASE_API_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      projectId: config.FIREBASE_PROJECT_ID,
      storageBucket: config.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
      appId: config.FIREBASE_APP_ID,
      measurementId: config.FIREBASE_MEASUREMENT_ID
    });

    const messagingApp = getMessaging(app);
    const deletePushToken = async () => {
      await deleteToken(messagingApp);
      savedToken.value = undefined;
    };
    const getPushToken = async () =>
      getToken(messagingApp, {
        vapidKey: config.VAPID_KEY,
        serviceWorkerRegistration: swRegister
      });

    const initToken = async () => {
      try {
        const currentToken = await getPushToken();
        if (savedToken.value !== currentToken) {
          const { isError } = await handleRequest(
            apiRoutes.profile.saveFcmTokens({
              token: currentToken
            })
          );
          if (!isError) {
            savedToken.value = currentToken;
          }
        }
      } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
      }
    };

    watch(
      isAuthenticated,
      isAuth => {
        if (isAuth) {
          initToken();
        } else if (savedToken.value) {
          deletePushToken();
        }
      },
      {
        immediate: true
      }
    );
  };
  init();
});
