const UserRoleMeet = {
  moderator: 'moderator',
  none: 'none'
} as const;

const EventsMeet = {
  setUserInfo: 'setUserInfo'
} as const;

const TrackTypeMeet = {
  audio: 'audio',
  video: 'video',
  desktop: 'desktop'
} as const;

const ToastMessageMeet = {
  terminated: 'The meeting has been terminated',
  adminKicked: 'JITSI.NOTIFY.ADMIN_KICKED',
  setRoleModerator: 'JITSI.NOTIFY.SET_ROLE_MODERATOR',
  backgroundEffectError: 'JITSI.NOTIFY.BACKGROUND_EFFECT_ERROR',
  webAssemblyWarningDescription: 'JITSI.NOTIFY.WEB_ASSEMBLY_WARNING_DESCRIPTION'
};

const CustomMeetEvent = {
  SharedVideo: 'shared-video',
  ToggleHand: 'toggle-hand'
};

const VideoShareStatus = {
  Play: 'play',
  Paused: 'paused',
  Stop: 'stop',
  Ended: 'ended'
} as const;

const MeetMessageReceived = {
  ASK_TO_UNMUTE: 'ASK_TO_UNMUTE'
};

const VirtualBackgroundType = {
  IMAGE: 'image',
  BLUR: 'blur',
  NONE: 'none'
};

type VirtualBackgroundImageT = {
  tooltip: string;
  id: string;
  src: string;
};

const VirtualBackgroundImages: VirtualBackgroundImageT[] = [
  {
    tooltip: 'image1',
    id: '1',
    src: '/assets/images/virtual-background/background-1.jpg'
  },
  {
    tooltip: 'image2',
    id: '2',
    src: '/assets/images/virtual-background/background-2.jpg'
  },
  {
    tooltip: 'image3',
    id: '3',
    src: '/assets/images/virtual-background/background-3.jpg'
  },
  {
    tooltip: 'image4',
    id: '4',
    src: '/assets/images/virtual-background/background-4.jpg'
  },
  {
    tooltip: 'image5',
    id: '5',
    src: '/assets/images/virtual-background/background-5.jpg'
  },
  {
    tooltip: 'image6',
    id: '6',
    src: '/assets/images/virtual-background/background-6.jpg'
  },
  {
    tooltip: 'image7',
    id: '7',
    src: '/assets/images/virtual-background/background-7.jpg'
  }
];

const CameraFacingMode = {
  ENVIRONMENT: 'environment',
  USER: 'user'
} as const;

type TrackTypeV = (typeof TrackTypeMeet)[keyof typeof TrackTypeMeet];
type UserRoleMeetV = (typeof UserRoleMeet)[keyof typeof UserRoleMeet];
type VideoShareStatusV = (typeof VideoShareStatus)[keyof typeof VideoShareStatus];
type MeetMessageReceivedV = (typeof MeetMessageReceived)[keyof typeof MeetMessageReceived];
type VirtualBackgroundTypeV = (typeof VirtualBackgroundType)[keyof typeof VirtualBackgroundType];
type CameraFacingModeV = (typeof CameraFacingMode)[keyof typeof CameraFacingMode];

export {
  UserRoleMeet,
  EventsMeet,
  TrackTypeMeet,
  ToastMessageMeet,
  CustomMeetEvent,
  VideoShareStatus,
  MeetMessageReceived,
  VirtualBackgroundType,
  VirtualBackgroundImages,
  CameraFacingMode,
  type TrackTypeV,
  type UserRoleMeetV,
  type VideoShareStatusV,
  type MeetMessageReceivedV,
  type VirtualBackgroundTypeV,
  type CameraFacingModeV
};
