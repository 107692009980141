<template>
  <div class="grid gap-2.5 lg:gap-7 max-w-3xl w-full text-center m-auto p-4">
    <common-auth-confirm-email-message />

    <div class="flex flex-col md:gap-6 gap-3 max-w-md mx-auto w-full">
      <div class="w-full">
        <client-only>
          <ui-button-async
            full
            text="Resend Email"
            :disabled="isActive"
            variant="secondary-outline"
            :request="apiRoutes.profile.resendConfirmEmail"
            @success="addTimestamp()"
          />
          <transition-scale>
            <ui-typography
              v-if="isActive"
              class="text-gray-500 mt-5"
              variant="h4"
            >
              {{ $t('You can resend the e-mail in') }} {{ formatTime }}
            </ui-typography>
          </transition-scale>
        </client-only>
      </div>
      <ui-button
        text="Logout"
        icon-right="logout"
        :loading="loading"
        @click="onLogout(false)"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
  definePageMeta({
    layout: 'auth',
    middleware: [
      'sanctum:auth',
      () => {
        const user = useSanctumUser<User>();
        const localePath = useLocalePath();
        if (user.value?.email_verified_at) {
          return navigateTo(localePath({ name: 'dashboard-profile' }));
        }
      }
    ],
    auth: {
      ignoreVerify: true
    }
  });
  const [onLogout, loading] = useLogout();

  const apiRoutes = useApiRoutes();
  const route = useRoute();
  const { routerReplace } = useRouterLocale();
  const toast = useToast();

  if (IS_BROWSER) {
    const error = route.query.error as string;
    if (error) {
      toast.error(error);
      routerReplace({ query: {} });
    }
  }

  const { formatTime, isActive, addTimestamp } = useDownTimerStorage('resendEmail', {
    immediate: true
  });
</script>
